define("bottomline-execution/models/truck-setting", ["exports", "@ember/object", "ember-data"], function (_exports, _object, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    // Truck settings' default values must be chosen such that BE frontend shows the correct (default) behavior even if no truck settings are configured on the backend.
    // To keep reasoning simple, all boolean truck settings should default to false.
    // Maintain alphabetical ordering here
    adr_report: attr('boolean', {
      defaultValue: false
    }),
    adr_report_request_attempts: attr('number', {
      defaultValue: 1
    }),
    // EP-1985
    adr_report_request_interval: attr('number', {
      defaultValue: 5
    }),
    // EP-1985
    authorize_trip_stop_driver_signature: attr('boolean', {
      defaultValue: false
    }),
    barrel_count_enabled: attr('boolean', {
      defaultValue: false
    }),
    batch_number_regexp: attr('string', {
      defaultValue: '^\\d{12}$'
    }),
    // EP-2007
    compartments: attr('boolean', {
      defaultValue: false
    }),
    customer_notification_on_arrival_enabled: attr('boolean', {
      defaultValue: false
    }),
    // EP-999
    customer_notification_on_departure_enabled: attr('boolean', {
      defaultValue: false
    }),
    // EP-999
    customer_type_to_filter_orders: attr('string', {
      defaultValue: false
    }),
    // BP-5838
    customer_type_with_flow_meter: attr('string', {
      defaultValue: false
    }),
    // BP-5838
    date_time_format: attr('string', {
      defaultValue: 'DD-MM-YYYY HH:mm'
    }),
    // EP-996: all values accepted by moment.js
    default_compartment_filter_strategy: attr('string', {
      defaultValue: 'Material ID',
      mapping: {
        false: '',
        true: ''
      }
    }),
    delivery_ticketnb_per_tank: attr('boolean', {
      defaultValue: false
    }),
    deprecated_source_adr_report: attr('string', {
      defaultValue: false
    }),
    // EP-796: false, 'jsreport', 'on-screen'
    deprecated_source_driver_report: attr('boolean', {
      defaultValue: false
    }),
    deprecated_source_trip_report: attr('boolean', {
      defaultValue: false
    }),
    deprecated_source_trip_stop_report: attr('boolean', {
      defaultValue: false
    }),
    derive_delivery_tank_15qty: attr('boolean', {
      defaultValue: false
    }),
    disable_standard_liters_aggregation: attr('boolean', {
      defaultValue: false
    }),
    dischargeplan_on_unload: attr('boolean', {
      defaultValue: false
    }),
    drop_down_count_enabled: attr('boolean', {
      defaultValue: false
    }),
    end_inventory_panel_materials: attr('boolean', {
      defaultValue: false
    }),
    external_reporting_url: attr('string', {
      defaultValue: '',
      mapping: {
        false: '',
        true: ''
      }
    }),
    // EP-1372
    hide_delivery_ticket_input: attr('boolean', {
      defaultValue: false
    }),
    hide_planned_qty_on_delivery: attr('boolean', {
      defaultValue: false
    }),
    inventory_in_percentage: attr('boolean', {
      defaultValue: false
    }),
    load_as_planned_enabled: attr('boolean', {
      defaultValue: false
    }),
    load_by_weight: attr('boolean', {
      defaultValue: false
    }),
    load_panel_materials: attr('boolean', {
      defaultValue: false
    }),
    load_ticketnb_per_tank: attr('boolean', {
      defaultValue: false
    }),
    location_tracking_disabled: attr('boolean', {
      defaultValue: false
    }),
    meter_connection_address: attr('string', {
      defaultValue: '192.168.0.3:5000'
    }),
    meter_connection_type: attr('string', {
      defaultValue: 'wifi'
    }),
    // EP-632: 'wifi', 'bluetooth'
    meter_coupling_active: attr('boolean', {
      defaultValue: false
    }),
    meter_inventory_on_load: attr('boolean', {
      defaultValue: false
    }),
    odometer_on_end_inventory: attr('boolean', {
      defaultValue: false
    }),
    // EP-1618: false, 'optional', 'mandatory'
    odometer_on_load: attr('boolean', {
      defaultValue: false
    }),
    // EP-1618: false, 'optional', 'mandatory'
    odometer_on_shift_level: attr('boolean', {
      defaultValue: false
    }),
    odometer_on_trip_level: attr('boolean', {
      defaultValue: false
    }),
    odometer_on_unload: attr('boolean', {
      defaultValue: false
    }),
    // EP-1618: false, 'optional', 'mandatory'
    on_delivery_capture: attr('boolean', {
      defaultValue: false
    }),
    on_delivery_capture_scan: attr('boolean', {
      defaultValue: false
    }),
    on_delivery_mandatory_picture: attr('boolean', {
      defaultValue: false
    }),
    on_delivery_scan: attr('boolean', {
      defaultValue: false
    }),
    on_end_inventory_capture: attr('boolean', {
      defaultValue: false
    }),
    on_end_inventory_capture_scan: attr('boolean', {
      defaultValue: false
    }),
    on_end_inventory_mandatory_picture: attr('boolean', {
      defaultValue: false
    }),
    on_end_inventory_mandatory_signatures: attr('boolean', {
      defaultValue: false
    }),
    on_end_inventory_scan: attr('boolean', {
      defaultValue: false
    }),
    on_load_capture: attr('boolean', {
      defaultValue: false
    }),
    on_load_capture_scan: attr('boolean', {
      defaultValue: false
    }),
    on_load_mandatory_picture: attr('boolean', {
      defaultValue: false
    }),
    on_load_mandatory_signatures: attr('boolean', {
      defaultValue: false
    }),
    on_load_scan: attr('boolean', {
      defaultValue: false
    }),
    on_unload_mandatory_signatures: attr('boolean', {
      defaultValue: false
    }),
    site_details_panel_show_external_site_id: attr('boolean', {
      defaultValue: false
    }),
    // EP-1776
    post_authorize_unload: attr('string', {
      defaultValue: false
    }),
    // EP-999: true, false, 'by_owner'
    post_delivery_pct_enabled: attr('boolean', {
      defaultValue: false
    }),
    // EP-999
    post_delivery_pressure_enabled: attr('boolean', {
      defaultValue: false
    }),
    // EP-999
    post_delivery_temp_enabled: attr('boolean', {
      defaultValue: false
    }),
    // EP-999
    postdip_enabled: attr('string', {
      defaultValue: false
    }),
    // EP-594: false, 'optional', 'required' EP-857: 'optional_by_ullage', 'required_by_ullage'
    postdip_validation_enabled: attr('boolean', {
      defaultValue: false
    }),
    pre_authorize_unload: attr('string', {
      defaultValue: false
    }),
    // EP-999: true, false, 'by_owner'
    pre_delivery_pct_enabled: attr('boolean', {
      defaultValue: false
    }),
    // EP-999
    pre_delivery_pressure_enabled: attr('boolean', {
      defaultValue: false
    }),
    // EP-999
    pre_delivery_temp_enabled: attr('boolean', {
      defaultValue: false
    }),
    // EP-999
    pre_delivery_ullage_validation_enabled: attr('boolean', {
      defaultValue: false
    }),
    // EP-999
    predip_enabled: attr('string', {
      defaultValue: false
    }),
    // EP-595: false, 'on_unload', 'on_pre_auth' EP-857: 'on_unload_by_ullage', 'on_pre_auth_by_ullage'
    register_site_coordinates: attr('boolean', {
      defaultValue: false
    }),
    register_tank_coordinates: attr('boolean', {
      defaultValue: false
    }),
    restrict_delivery_tickets_for_customer_type: attr('string', {
      defaultValue: false
    }),
    // BP-5838
    shifts_panel_geographical_map: attr('boolean', {
      defaultValue: false
    }),
    shifts_panel_trip_stops: attr('boolean', {
      defaultValue: false
    }),
    shifts_panel_remarks: attr('boolean', {
      defaultValue: false
    }),
    show_ambient_liters_on_load: attr('boolean', {
      defaultValue: false
    }),
    // BXBE-230: false, true
    show_ambient_liters_on_unload: attr('boolean', {
      defaultValue: false
    }),
    // BXBE-230: false, true
    show_density_on_load: attr('boolean', {
      defaultValue: false
    }),
    // EP-1557
    show_materials_on_end_inventory: attr('string', {
      defaultValue: false
    }),
    // EP-1465: false, 'relevant', 'all'
    show_materials_on_load: attr('string', {
      defaultValue: false
    }),
    // EP-1304: false, 'relevant', 'all'
    show_return_materials: attr('boolean', {
      defaultValue: false
    }),
    show_standard_liters_on_load: attr('string', {
      defaultValue: false
    }),
    // BXBE-225: false, true, 'open', 'closed'
    show_standard_liters_on_unload: attr('string', {
      defaultValue: false
    }),
    // BXBE-225: false, true, 'open', 'closed'
    show_tanks_on_unload: attr('string', {
      defaultValue: false
    }),
    // EP-1464: false, 'relevant', 'all'
    show_temperature_on_load: attr('boolean', {
      defaultValue: false
    }),
    show_temperature_on_unload: attr('boolean', {
      defaultValue: false
    }),
    show_trip_id_in_trip_overview: attr('boolean', {
      defaultValue: false
    }),
    small_screen_ui: attr('boolean', {
      defaultValue: false
    }),
    tank_is_full_enabled: attr('boolean', {
      defaultValue: false
    }),
    terminal_import_enabled: attr('boolean', {
      defaultValue: false
    }),
    trips_panel_actions_add_stop_from_order: attr('boolean', {
      defaultValue: false
    }),
    trips_panel_actions_add_stop_from_site: attr('boolean', {
      defaultValue: false
    }),
    trips_panel_actions_change_terminal: attr('boolean', {
      defaultValue: false
    }),
    trips_panel_actions_material_transfer: attr('boolean', {
      defaultValue: false
    }),
    trips_panel_actions_reorder_trip_stops: attr('boolean', {
      defaultValue: false
    }),
    trips_panel_atg_information: attr('boolean', {
      defaultValue: false
    }),
    trips_panel_atg_warnings: attr('boolean', {
      defaultValue: false
    }),
    trips_panel_current_inventory: attr('boolean', {
      defaultValue: false
    }),
    trips_panel_geographical_map: attr('boolean', {
      defaultValue: false
    }),
    trips_panel_material_calculation: attr('boolean', {
      defaultValue: false
    }),
    trips_panel_remarks_for_driver: attr('boolean', {
      defaultValue: false
    }),
    trips_panel_site_details: attr('boolean', {
      defaultValue: false
    }),
    truck_compartments_order_desc: attr('boolean', {
      defaultValue: false
    }),
    unit_of_calculation: attr('string', {
      defaultValue: 'ambient liters'
    }),
    // BXBE-226: 'ambient liters', 'standard liters'
    use_access_point: attr('boolean', {
      defaultValue: false
    }),
    use_material_calculation: attr('boolean', {
      defaultValue: false
    }),
    webcam_enabled_for_webversion: attr('boolean', {
      defaultValue: false
    }),
    // Maintain alphabetical ordering here
    settingNames: (0, _object.computed)(function () {
      return Object.keys(this.serialize());
    }),
    validate: function validate() {
      if (this.unit_of_calculation == 'ambient liters') {
        if (!(this.show_ambient_liters_on_load && this.show_ambient_liters_on_unload)) {
          return 'truck_setting.errors.unit_of_calculation';
        }
      }

      if (this.unit_of_calculation == 'standard liters') {
        if (!(this.show_standard_liters_on_load && this.show_standard_liters_on_unload)) {
          return 'truck_setting.errors.unit_of_calculation';
        }
      }
    }
  });

  _exports.default = _default;
});