define("bottomline-execution/components/site/address-details", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils", "bottomline-execution/mixins/app-state"], function (_exports, _component, _object, _computed, _utils, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_appState.default, {
    classNames: 'address-details',
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    showPhoneNumber: (0, _computed.notEmpty)('site.Telephone'),
    showExtSiteId: (0, _object.computed)('truckSettings.site_details_panel_show_external_site_id', 'site.ExtSiteID', function () {
      return this.get('truckSettings.site_details_panel_show_external_site_id') == true && (0, _utils.isPresent)(this.get('site.ExtSiteID'));
    })
  });

  _exports.default = _default;
});