define("bottomline-execution/utils/save-b64-to-file-util", ["exports", "bottomline-execution/utils/data-uri-to-blob-util", "rsvp"], function (_exports, _dataUriToBlobUtil, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.saveB64ToFileUtil = saveB64ToFileUtil;

  // cordova.file.dataDirectory points to /data/user/0/eu.bottomline.Execution/files/
  function saveB64ToFileUtil(content, fileName) {
    var directoryPath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : cordova.file.dataDirectory;
    var dataBlob = (0, _dataUriToBlobUtil.dataURItoBlobUtil)("data:image/jpeg;base64,".concat(content));
    return new _rsvp.default.Promise(function (resolve, reject) {
      return window.resolveLocalFileSystemURL(directoryPath, function (directory) {
        directory.getFile(fileName, {
          create: true
        }, function (file) {
          file.createWriter(function (fileWriter) {
            fileWriter.onwriteend = function (_event) {
              resolve(fileName);
            };

            fileWriter.write(dataBlob);
          }, function () {
            reject(null);
          });
        });
      });
    });
  }
});