define("bottomline-execution/templates/components/site/address-details", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "P1NjSMeC",
    "block": "[[[41,[30,0,[\"site\"]],[[[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[1,[30,0,[\"site\",\"Name\"]]],[1,\" \"],[1,[52,[33,1],[30,0,[\"site\",\"ExtSiteID\"]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[1,[30,0,[\"site\",\"Address\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[1,[30,0,[\"site\",\"ZipCode\"]]],[1,\" \"],[1,[30,0,[\"site\",\"City\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showPhoneNumber\"]],[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[6,[39,2],null,[[\"href\",\"styleGroups\",\"additionalCssClasses\"],[[28,[37,3],[\"tel:\",[30,0,[\"site\",\"telephoneNumbered\"]]],null],\"icon-link-button\",\"address-details__phone-button\"]],[[\"default\"],[[[[1,[28,[35,4],null,[[\"name\",\"size\"],[\"phone\",\"lg\"]]]]],[]]]]],[1,\"\\n\\n      \"],[10,3],[15,6,[29,[\"tel:\",[30,0,[\"site\",\"telephoneNumbered\"]]]]],[12],[1,[30,0,[\"site\",\"Telephone\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],[[[1,\"  \"],[10,0],[14,0,\"well\"],[12],[1,\"\\n    \"],[1,[28,[35,5],null,[[\"key\"],[\"select_trip_stop\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"showExtSiteId\",\"bl-components/link-button\",\"concat\",\"material-design-icon\",\"editable-t\"]]",
    "moduleName": "bottomline-execution/templates/components/site/address-details.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});